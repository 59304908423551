<template>
  <v-dialog v-model="drawer" scrollable persistent max-width="800">
    <v-form class="fill-height" @submit.prevent v-if="obModel && drawer">
      <gw-form-observer
        @save="save"
        @cancel="cancel"
        :loading="isLoading"
        hide-top-actions
      >
        <settings v-model="obModel" @save="save" />
      </gw-form-observer>
    </v-form>
  </v-dialog>
</template>

<script lang="ts">
import { Mixins, Component, PropSync } from "vue-property-decorator";
import BanksMixin from "@/modules/banks/mixins/BanksMixin";
import Settings from "@/modules/banks/components/tabs/Settings.vue";

@Component({
  components: {
    Settings,
  },
})
export default class BanksForm extends Mixins(BanksMixin) {
  @PropSync("open", { type: Boolean, default: false }) drawer!: boolean;
}
</script>
