<template>
  <v-row>
    <v-col cols="12">
      <v-switch :label="$t('active')" v-model="obBank.active"></v-switch>
    </v-col>

    <v-col cols="12" lg="6" xl="4">
      <form-field-text
        ref="elNameField"
        v-model="obBank.name"
        @save="save"
        autofocus
        required
      />
    </v-col>

    <v-col cols="12" lg="6" xl="4">
      <form-field-text
        v-model="obBank.code"
        label="code"
        @save="save"
        required
      />
    </v-col>
  </v-row>
</template>

<script lang="ts">
import { Vue, Component, VModel } from "vue-property-decorator";
import { Bank } from "@planetadeleste/vue-mc-gw";

@Component
export default class BanksSettings extends Vue {
  @VModel({ type: Object, default: () => new Bank() })
  obBank!: Bank;

  save() {
    this.$emit("save");
  }
}
</script>
